<template>
  <div class="content-wrap">
    <div class="content-select">
      <income-authorized
        @org-change="handleChangeTaxPayer"
        style="padding: 12px 110px 12px 24px"
      ></income-authorized>
      <div class="select-content">
        <el-form class="select-form" ref="queryForm" :model="queryForm" label-width="100px" @keyup.enter.native="submitForm">
          <div>
            <el-form-item prop="checkStatus" label="勾选状态">
              <el-select v-model="queryForm.checkStatus" placeholder="请选择勾选状态">
                <el-option v-for="item in checkStatuses" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="invoiceTime" label="开票日期">
              <el-date-picker size="mini" v-model="invoiceTimeRange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" range-separator="~" type="daterange" :picker-options="gxrqDateRangeOptions"> </el-date-picker>
            </el-form-item>
            <el-form-item prop="invoiceCode" label="发票代码">
              <el-input v-model.trim="queryForm.invoiceCode" placeholder="请输入发票代码" maxlength="12" @keyup.enter.native="submitForm()" />
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item prop="invoiceNo" label="发票号码">
              <el-input v-model.trim="queryForm.invoiceNo" placeholder="请输入发票号码" maxlength="8" @keyup.enter.native="submitForm()" />
            </el-form-item>
            <el-form-item prop="checkedTime" label="勾选日期">
              <el-date-picker size="mini" v-model="checkedTimeRange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" range-separator="~" type="daterange" :picker-options="dateOption"> </el-date-picker>
            </el-form-item>
            <el-form-item prop="invoiceType" label="发票类型">
              <el-select v-model="queryForm.invoiceType" placeholder="请选择发票类型">
                <el-option v-for="item in invoiceTypes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item prop="sellerName" label="销方名称">
              <el-input v-model.trim="queryForm.sellerName" placeholder="请输入销方名称" @keyup.enter.native="submitForm()" />
            </el-form-item>
            <el-form-item prop="submitTime" label="提交时间">
              <el-date-picker size="mini" v-model="submitTimeRange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" range-separator="~" type="daterange" :picker-options="dateOption"> </el-date-picker>
            </el-form-item>
            <el-form-item prop="checker" label="勾选人">
              <el-input v-model.trim="queryForm.checker" placeholder="请输入勾选人名称" @keyup.enter.native="submitForm()" />
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item prop="receiverName" label="收票人">
              <el-input v-model.trim="queryForm.receiverName" placeholder="请输入收票人名称" @keyup.enter.native="submitForm()" />
            </el-form-item>
            <el-form-item prop="receiveTime" label="收票时间">
              <el-date-picker size="mini" v-model="receiveTimeRange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" range-separator="~" type="daterange" :picker-options="dateOption"> </el-date-picker>
            </el-form-item>
            <el-form-item prop="eInvoiceNo" label="数电票号">
              <el-input maxlength="20" v-model.trim="queryForm.eInvoiceNo" placeholder="请输入数电票号" @keyup.enter.native="submitForm()" />
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item prop="matchStatus" label="匹配状态">
              <el-select v-model="queryForm.matchStatus" placeholder="请选择匹配状态">
                <el-option v-for="item in matchingState" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-show="selectExpended" style="margin-bottom: 40px">
            <el-form-item label="扫码查询">
              <el-input v-model="invoiceQrCode" :rows="3" type="textarea" style="min-width: 840px; z-index: 1" placeholder="点击文本框获取焦点，扫描录入时请切换至英文输入法！" @keyup.enter.native.stop="handleAnalysisQrCode" />
            </el-form-item>
          </div>
          <div>
            <el-form-item style="margin-left: 32px">
              <select-button show-status @list-close="handleListClose" @list-show="handleListShow" @select="submitForm" @reset="handleReset"></select-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="ygx_total_box" v-loading="ygxLoading">
          <span
            >已勾选发票数：<label>{{ ygxSummary.total }}</label
            >&nbsp;张</span
          >
          <span
            >发票税额：<label
              >￥{{ formatMoney(ygxSummary.taxAmount) }}</label
            ></span
          >
          <span
            >有效税额：<label
              >￥{{ formatMoney(ygxSummary.deductTaxAmount) }}</label
            ></span
          >
          <span
            >发票金额：<label
              >￥{{ formatMoney(ygxSummary.amount) }}</label
            ></span
          >
          <el-button
            type="text"
            @click="handleYgxSummary"
            icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
        <p-header :title="'当期可勾选发票的开票日期范围为：' + dateRangeFmt">
          <el-tooltip
            :disabled="isReadied"
            placement="top"
            style="margin-right: 10px"
            content="请保持认证设备在线"
          >
            <span>
              <el-button
                type="success"
                plain
                :disabled="!isReadied"
                :loading="buttonLoading"
                @click="handleSyncInvoiceStatus"
                >同步发票状态
              </el-button>
            </span>
          </el-tooltip>
          <el-tooltip :disabled="isReadied && !isApply" style="margin-right: 10px" placement="top">
            <div slot="content">
              <span v-if="isApply">本月已完成统计，想要提交勾选或者取消勾选，请先撤销本月统计。</span>
              <span v-else>请保持认证设备在线</span>
            </div>
            <span>
              <el-button type="primary" plain :disabled="!isReadied || isApply" :loading="buttonLoading" @click="handleRetract">取消抵扣勾选 </el-button>
            </span>
          </el-tooltip>
          <el-tooltip :disabled="isReadied && !isApply" placement="top" style="margin-right: 10px">
            <div slot="content">
              <span v-if="isApply">本月已完成统计，想要提交勾选或者取消勾选，请先撤销本月统计。</span>
              <span v-else>请保持认证设备在线</span>
            </div>
            <span>
              <el-button type="primary" :disabled="!isReadied || isApply" @click="handleChecked" :loading="buttonLoading">提交抵扣勾选 </el-button>
            </span>
          </el-tooltip>
          <el-button type="default" :loading="buttonLoading" @click="handleExport">导出 </el-button>
        </p-header>
        <div class="content-table">
          <el-table :data="tableData" stripe border ref="selectionTable" highlight-current-row v-loading="tableLoading" @selection-change="handleSelection" :header-cell-style="handleHeaderCellStyle" style="width: 100%">
            <el-table-column type="selection" width="40" fixed="left" :selectable="handleRedInvoice" />
            <el-table-column type="index" label="序号" width="60" fixed="left"></el-table-column>
            <el-table-column prop="invoiceType" :formatter="fmtTableColumn" label="发票类型" width="140"></el-table-column>
            <el-table-column prop="invoiceCode" label="发票代码" width="110"></el-table-column>
            <el-table-column prop="invoiceNo" label="发票号码" width="110"></el-table-column>
            <el-table-column prop="eInvoiceNo" label="数电票号" min-width="120" />
            <el-table-column prop="matchStatus" :formatter="fmtTableColumn" label="匹配状态" min-width="120" />
            <el-table-column prop="invoiceTime" :formatter="fmtTableColumn" label="开票日期" width="110"></el-table-column>
            <el-table-column prop="sellerName" show-overflow-tooltip label="销方名称" min-width="140"></el-table-column>
            <el-table-column prop="amount" label="金额"></el-table-column>
            <el-table-column prop="taxAmount" label="税额"></el-table-column>
            <el-table-column prop="deductTaxAmount" label="有效税额" width="140" style="padding: 0 4px">
              <template slot-scope="scope">
                <el-input
                  style="width: 110px"
                  v-float="2"
                  @blur="checkDeductTaxAmount(scope.row)"
                  v-model="scope.row.deductTaxAmount"
                  :disabled="
                    !(
                      scope.row.enable &&
                      scope.row.checkStatus == 'WGX' &&
                      !seEditable
                    )
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="receiverName" label="收票人"></el-table-column>
            <el-table-column prop="receiveTime" label="收票时间" width="110"></el-table-column>
            <el-table-column prop="checkStatus" :formatter="fmtTableColumn" label="勾选状态" width="100"></el-table-column>
            <el-table-column prop="manageStatus" :formatter="fmtTableColumn" label="管理状态" width="100"></el-table-column>
            <el-table-column prop="invoiceStatus" :formatter="fmtTableColumn" label="发票状态"></el-table-column>
            <el-table-column prop="submitTime" label="提交时间" width="180" :formatter="fmtTableColumn"></el-table-column>
            <el-table-column prop="checkedTime" :formatter="fmtTableColumn" label="勾选日期" width="180"></el-table-column>
            <el-table-column prop="checker" label="勾选人" width="100">
              <template slot-scope="scope">
                {{ scope.row.checker == '' ? '--' : scope.row.checker }}
              </template>
            </el-table-column>
            <el-table-column prop="syncedTime" label="更新时间" width="180"></el-table-column>
            <el-table-column prop="failedMessage" label="提示" width="80">
              <template slot-scope="scope">
                <el-tooltip v-if="scope.row.failedMessage" class="item" effect="dark" placement="top">
                  <div slot="content">{{ scope.row.failedMessage }}</div>
                  <span><i class="el-icon-warning"></i>错误</span>
                </el-tooltip>
                <span v-else>正确</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="table-statistics">
            <div class="statistics-left">
              <div>
                已选中：<span style="color: red"> {{ selected.total }} </span
                >&nbsp;条
              </div>
              <div>金额：{{ "￥" + formatMoney(selected.amount) }}</div>
              <div>税额：{{ "￥" + formatMoney(selected.taxAmount) }}</div>
              <div>
                有效税额：{{ "￥" + formatMoney(selected.deductTaxAmount) }}
              </div>
            </div>
            <div class="statistics-right" v-show="oprtMode == 'CX'">
              <div>总数量：{{ summary.total }}&nbsp;条</div>
              <div>总金额：{{ "￥" + formatMoney(summary.amount) }}</div>
              <div>总税额：{{ "￥" + formatMoney(summary.taxAmount) }}</div>
              <div>
                有效税额：{{ "￥" + formatMoney(summary.deductTaxAmount) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-page">
        <el-pagination
          background
          v-show="oprtMode == 'CX'"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.page"
          :page-sizes="pageSizes"
          :page-size="queryForm.size"
          layout="total, sizes, prev, pager, next"
          :total="summary.total"
        >
        </el-pagination>
      </div>
    </div>
    <confirm-deduction-dialog
      :visible.sync="visible"
      :is-checked="isChecked"
      :orgId="queryForm.orgId"
      :tax-no="queryForm.taxNo"
      :selections="selections"
      :tax-period="taxPeriod"
      @success="handleConfirmSuccess"
      @failed="handleConfirmFailed"
    >
    </confirm-deduction-dialog>
    <sync-data
      class="sync-data"
      current="without"
      :is-readied="isReadied"
      @sync-data="submitForm"
    ></sync-data>
  </div>
</template>

<script>
import { fmtMoney } from "@/assets/js/format-util";
import { parseInvoiceQrCode } from "@/assets/js/invoice-util";
import RegexLib from "@/assets/js/regex-lib";
import SyncData from "@/views/income-selection/components/SyncData";
import IncomeAuthorized from "./components/IncomeAuthorized";
import SelectButton from "@/components/buttons/SelectButton";
import PHeader from "@/components/pdt/PHeader";
import ConfirmDeductionDialog from "./components/ConfirmDeductionDialog";
import { tableStyle, pagingMethods, dateOption } from "../../util/mixins";
import {
  pagingDeductInvoiceUnauthenticatedList,
  deductGetInvoice,
  downloadDeductExcel,
  getIncomeInvoicesStatistics,
} from "@/service/deduction";

export default {
  name: 'Selection',
  components: {
    PHeader,
    SelectButton,
    IncomeAuthorized,
    ConfirmDeductionDialog,
    SyncData
  },
  mixins: [tableStyle, pagingMethods, dateOption],
  data() {
    return {
      oprtMode: 'CX', //CX=查询模式 SM=扫码模式
      invoiceQrCode: '',
      isReadied: false,
      isApply: false,
      queryForm: {
        orgId: '',
        taxNo: '',
        checkStatus: 'WGX',
        invoiceCode: undefined,
        invoiceNo: undefined,
        invoiceType: undefined,
        sellerName: undefined,
        checker: undefined,
        creatorName: undefined,
        eInvoiceNo: undefined,
        matchStatus: undefined,
        page: 1,
        size: 30,
      },
      ygxLoading: false,
      ygxSummary: {
        total: 0,
        amount: 0,
        taxAmount: 0,
        deductTaxAmount: 0,
      },
      summary: {
        total: 0,
        amount: 0,
        taxAmount: 0,
        deductTaxAmount: 0
      },
      selected: {
        total: 0,
        amount: 0,
        taxAmount: 0,
        deductTaxAmount: 0
      },
      submitTimeRange: [],
      invoiceTimeRange: [],
      receiveTimeRange: [],
      checkedTimeRange: [],
      taxPeriod: '',
      taxNo: '',
      isChecked: false, // 提交操作： 勾选/撤销
      tableLoading: false,
      buttonLoading: false, // 行为按钮loading
      selectExpended: false,
      visible: false,
      checkStatuses: [{ value: '', label: '全部' }, ...this.$store.state.enums.DeductInvoiceCheckStatus],
      invoiceTypes: [{ value: '', label: '全部' }, ...this.$store.state.enums.DeductInvoiceType],
      matchingState: [
        { value: '', label: '全部' },
        { value: '0', label: '未匹配' },
        { value: '1', label: '已匹配 ' },
        { value: '2', label: '匹配失败' }
      ],
      // 进项发票信息
      dateRange: '',
      selections: [],
      tableData: [],
      gxrqDateRangeOptions: {
        disabledDate: (time) => {
          console.log('计算禁止日期...');
          return time.getTime() > this.getGxfwEndDate();
        }
      }
    };
  },
  computed: {
    dateRangeFmt() {
      if (this.dateRange) {
        let arr = this.dateRange.split('');
        arr.splice(17, 0, '日');
        arr.splice(15, 0, '月');
        arr.splice(13, 0, '年');
        arr.splice(8, 0, '日');
        arr.splice(6, 0, '月');
        arr.splice(4, 0, '年');
        return arr.join('');
      } else {
        return "--";
      }
    },
    seEditable() {
      switch (this.$store.state.deductAuthorize.sqpt) {
        case "RPA":
          return false;
        default:
          return true;
      }
    },
  },
  watch: {
    selections: {
      handler() {
        this.tableData.forEach((i) => (i.enable = false));
        let totalAmount = 0,
          totalTaxAmount = 0,
          totalDeductTaxAmount = 0;
        this.selections.forEach((i) => {
          i.enable = true;
          totalAmount = Number(Number(totalAmount + Number(i.amount)).toFixed(2));
          totalTaxAmount = Number(Number(totalTaxAmount + Number(i.taxAmount)).toFixed(2));
          totalDeductTaxAmount = Number(Number(totalDeductTaxAmount + Number(i.deductTaxAmount)).toFixed(2));
        });
        this.selected = {
          total: this.selections.length,
          amount: totalAmount,
          taxAmount: totalTaxAmount,
          deductTaxAmount: totalDeductTaxAmount
        };
        this.tableData.forEach((i) => {
          if (!i.enable) i.deductTaxAmount = i.taxAmount;
        });
      },
      deep: true
    }
  },
  methods: {
    /* 解析二维码 */
    async handleAnalysisQrCode() {
      if (this.invoiceQrCode === '') return;
      if (!this.queryForm.orgId) {
        this.toast('请您先选择收票主体！', 'warning');
        return;
      }

      if (this.oprtMode == 'CX') {
        this.oprtMode = 'SM';
        this.tableData = [];
      }

      console.log('发票二维码原始字符串：', this.invoiceQrCode);
      let content = this.invoiceQrCode.replace('/，/g', ',');
      const res = parseInvoiceQrCode(content);

      const { success, invoice } = res;
      console.log('解析发票二维码的结果：', res);

      if (success) {
        // 校验数据
        const errmsg = this.validateInvoiceContent(invoice);
        if (errmsg != '') {
          this.toast(errmsg, 'warning');
          return;
        }

        // 判断是否已存在
        const invoiceIndex = this.tableData.findIndex((item) => {
          if (['FullEleSpecInvoice', 'FullEleGenerInvoice'].indexOf(invoice.invoiceType) != -1) {
            return item.eInvoiceNo == invoice.invoiceNo;
          } else {
            return item.invoiceCode == invoice.invoiceCode && item.invoiceNo == invoice.invoiceNo;
          }
        });
        if (invoiceIndex != -1) {
          this.toast('扫描失败，列表中已存在该票！', 'warning');
          return;
        }

        let param = {
          orgId: this.queryForm.orgId,
          taxNo: this.queryForm.taxNo,
          invoiceCode: undefined,
          invoiceNo: undefined,
          eInvoiceNo: undefined,
          page: 1,
          size: 10,
        };
        if (['FullEleSpecInvoice', 'FullEleGenerInvoice'].indexOf(invoice.invoiceType) != -1) {
          param.eInvoiceNo = invoice.eInvoiceNo;
        } else {
          param.invoiceCode = invoice.invoiceCode;
          param.invoiceNo = invoice.invoiceNo;
        }

        this.tableLoading = true;
        const { success, data } = await pagingDeductInvoiceUnauthenticatedList(param);
        if (success) {
          if (data.records.length > 0) {
            this.tableData = [...data.records, ...this.tableData];
            this.toast('扫描成功，已将发票添至列表！', 'success');
          } else {
            this.toast('扫描失败，未查询到指定发票！', 'warning');
          }
        }

        this.invoiceQrCode = '';
        this.tableLoading = false;
      }
    },
    handleYgxSummary() {
      this.doGetIncomeInvoicesYGXStatistics({
        checkStatus: "YGX",
        orgId: this.queryForm.orgId,
        taxNo: this.queryForm.taxNo,
      });
    },
    /* 数据统计 */
    async doGetIncomeInvoicesStatistics(param) {
      const { success, data } = await getIncomeInvoicesStatistics(param);
      if (success) {
        this.summary = data;
      }
    },
    /* 提交前检查 */
    validateInvoiceContent(invoice) {
      if (['GenerInvoice', 'SpecInvoice', 'EleInvoice', 'EleSpecInvoice', 'FullElePaperGenerInvoice', 'FullElePaperSpecInvoice', 'FullEleSpecInvoice', 'FullEleGenerInvoice'].indexOf(invoice.invoiceType) < 0) {
        return '扫描失败，不支持的发票类型！';
      }
      // 全电票没有发票代码
      if (invoice.invoiceType != 'FullEleSpecInvoice' && invoice.invoiceType != 'FullEleGenerInvoice') {
        if (!RegexLib.InvoiceCode.test(invoice.invoiceCode)) {
          return '扫描失败，税控发票代码无效！';
        }
        if (!RegexLib.InvoiceNo.test(invoice.invoiceNo)) {
          return '扫描失败，税控发票号码无效！';
        }
      }
      return '';
    },

    // 分页查询
    submitForm() {
      let arg = this.getQueryParam();
      // console.log("arg======", arg);
      this.refreshTableData(arg);
    },
    handleReset() {
      this.queryForm = {
        orgId: '',
        taxNo: '',
        checkStatus: 'WGX',
        invoiceCode: undefined,
        invoiceNo: undefined,
        invoiceType: undefined,
        sellerName: undefined,
        checker: undefined,
        creatorName: undefined,
        eInvoiceNo: undefined,
        matchStatus: undefined
      }
      this.invoiceQrCode = undefined
    },
    // 收起筛选列表
    handleListClose() {
      this.selectExpended = false;
    },
    // 展开筛选列表
    handleListShow() {
      this.selectExpended = true;
    },
    //处理红票
    handleRedInvoice(row) {
      if (row.amount < 0) {
        return false;
      }
      return true;
    },

    // 组织切换
    handleChangeTaxPayer(val) {
      console.log("handleChangeTaxPayer...", JSON.stringify(val));

      this.queryForm.orgId = val.orgId;
      this.queryForm.taxNo = val.taxNo;
      this.submitForm();

      this.isReadied = val.isReadied; // 授权就绪状态
      this.isApply = !val.sfkysq; // 是否已经申请
      this.taxPeriod = val.dqssq; // 当前所属税期
      this.taxNo = val.taxNo; // 纳税主体税号
      this.dateRange = val.gxfpfw; // 勾选发票范围
    },
    // 计算日期范围
    getQueryParam() {
      let queryParam;
      queryParam = this.addDateRange(
        this.queryForm,
        this.submitTimeRange,
        "SubmitTime"
      );
      queryParam = this.addDateRange(
        queryParam,
        this.invoiceTimeRange,
        "InvoiceTime"
      );
      queryParam = this.addDateRange(
        queryParam,
        this.receiveTimeRange,
        "ReceiveTime"
      );
      queryParam = this.addDateRange(
        queryParam,
        this.checkedTimeRange,
        "CheckedTime"
      );
      // console.log("get======", queryParam);
      return queryParam;
    },
    // 重置表单
    resetForm() {
      this.$refs.queryForm.resetFields();

      this.submitTimeRange = [];
      this.invoiceTimeRange = [];
      this.receiveTimeRange = [];
      this.checkedTimeRange = [];
      let orgId = this.queryForm.orgId;
      let taxNo = this.queryForm.taxNo;
      this.queryForm = {
        orgId: orgId,
        taxNo: taxNo,
        checkStatus: 'WGX',
        invoiceCode: undefined,
        invoiceNo: undefined,
        invoiceType: undefined,
        sellerName: undefined,
        checker: undefined,
        creatorName: undefined,
        eInvoiceNo: undefined,
        matchStatus: undefined,
        page: 1,
        size: 30,
      };
      this.invoiceQrCode = undefined;
    },
    // 更新发票列表数据
    async refreshTableData(param) {
      if (this.oprtMode == 'SM') {
        this.$confirm('您当前处于扫码查询模式，切换至普通查询模式后会清空扫码查询模式的查询结果，您确认要继续吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            this.oprtMode = 'CX';
            await this.doGetDataList(param);
          })
          .catch(() => {});
      } else {
        await this.doGetDataList(param);
      }
    },
    // 已勾选汇总
    async doGetIncomeInvoicesYGXStatistics(param) {
      this.ygxLoading = true;
      const { success, data } = await getIncomeInvoicesStatistics(param);
      if (success) {
        this.ygxSummary = data;
      }
      this.ygxLoading = false;
    },
    async doGetDataList(param) {
      this.tableLoading = true;
      // 初始化表格数据
      this.tableData = [];
      if (!param.taxNo) {
        this.toast("查询失败，选择的组织非纳税主体！", "warning");
        return;
      }
      this.doGetIncomeInvoicesStatistics(param);
      this.doGetIncomeInvoicesYGXStatistics({
        checkStatus: "YGX",
        orgId: param.orgId,
        taxNo: param.taxNo,
      });

      const { success, data } = await pagingDeductInvoiceUnauthenticatedList(
        param
      );
      this.tableLoading = false;
      if (this.oprtMode == 'SM') {
        this.oprtMode = 'CX';
      }
      if (success) {
        this.tableData = data.records;
      }
    },
    // 点击导出
    async handleExport() {
      if (!this.selections || this.selections.length < 1) {
        this.toast('导出发票至少勾选一张', 'warning');
        return;
      }
      let ids = this.selections.map((i) => i.id);
      this.buttonLoading = true;
      const rsp = await downloadDeductExcel(ids);
      this.downloadFile(rsp);
      this.buttonLoading = false;
    },
    // 表格勾选
    handleSelection(val, row) {
      this.selections = val;
    },
    // 提交勾选
    handleChecked() {
      if (!this.selections || this.selections.length < 1) {
        this.toast('请勾选需要抵扣的发票', 'warning');
        return;
      }
      for (let i of this.selections) {
        if (['YGX', 'QXZ'].includes(i.checkStatus)) {
          this.toast('只能选择“未勾选、勾选中发票', 'error');
          return;
        }
      }
      this.buttonLoading = true;
      this.isChecked = true;
      this.visible = true;
    },
    // 撤回勾选
    handleRetract() {
      if (!this.selections || this.selections.length < 1) {
        this.toast('请选择需要取消抵扣勾选的发票', 'warning');
        return;
      }
      for (let i of this.selections) {
        if (['WGX', 'GXZ'].includes(i.checkStatus)) {
          this.toast('未勾选、勾选中状态的发票不可执行取消勾选操作，请重新选择', 'error');
          return;
        }
      }
      this.buttonLoading = true;
      this.isChecked = false;
      this.visible = true;
    },
    // 同步发票状态:单票查询
    async handleSyncInvoiceStatus() {
      if (!this.selections || this.selections.length < 1) {
        this.toast('至少勾选一张发票', 'warning');
        return;
      }
      this.buttonLoading = true;
      const { success } = await deductGetInvoice({
        taxNo: this.$store.state.deductAuthorize.taxNo,
        ids: this.selections.map((i) => i.id)
      });
      if (success) {
        this.toast("数据同步成功", "success", () => this.submitForm());
      }
      this.buttonLoading = false;
    },
    // 勾选弹窗关闭的回调
    handleConfirmSuccess() {
      this.buttonLoading = false;
      this.$alert(
        this.isChecked ? "提交抵扣勾选成功！" : "取消抵扣勾选成功！",
        "提示",
        {
          confirmButtonText: "确定",
          callback: () => {
            this.oprtMode = "CX";
            this.submitForm();
          },
        }
      );
    },
    handleConfirmFailed() {
      this.buttonLoading = false;
    },
    // 有效税额校验
    checkDeductTaxAmount(row) {
      if (row.deductTaxAmount > row.taxAmount) {
        this.toast('有效税额不可大于税额', 'warning');
        row.deductTaxAmount = row.taxAmount;
      }
    },
    // 格式化表格
    fmtTableColumn(row, column) {
      switch (column.property) {
        case 'invoiceType':
          return this.handleValueToLabel('DeductInvoiceType', row[column.property]);
        case 'checkStatus':
          return this.handleValueToLabel('DeductInvoiceCheckStatus', row[column.property]);
        case 'manageStatus':
          return this.handleValueToLabel('DeductInvoiceManageStatus', row[column.property]);
        case 'invoiceStatus':
          return this.handleValueToLabel('DeductInvoiceStatus', row[column.property]);
        case 'matchStatus':
          console.log(row[column.property]);
          if (row[column.property] === '0') {
            return '未匹配';
          } else if (row[column.property] === '1') {
            return '已匹配';
          } else if (row[column.property] === '2') {
            return '匹配失败';
          } else {
            return '--';
          }
        case 'invoiceTime':
          return this.$moment(row[column.property]).format('YYYY-MM-DD');
        case 'checkedTime':
          return row[column.property] == null ? '--' : this.$moment(row[column.property]).format('YYYY-MM-DD');
        case 'submitTime':
          return row[column.property] == null ? '--' : row[column.property];
      }
    },
    getGxfwEndDate() {
      const invoiceTimeEndStr = this.$store.state.deductAuthorize.invoiceTimeEnd;
      return new Date(invoiceTimeEndStr).getTime();
    },
    formatMoney(value) {
      return fmtMoney(value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/select.scss';

.ygx_total_box {
  display: flex;
  padding: 10px 16px;
  border-width: 1px;
  border-radius: 8px;
  border-color: #dddddd;
  border-style: solid;
  margin: 10px 10px 0px 10px;
  span {
    color: #333;
    font-size: 14px;
    margin-right: 48px;
    display: flex;
    align-items: center;
    label {
      font-weight: 800;
      font-size: 16px;
      color: red;
    }
  }
}
.content-wrap {
  position: relative;
}

.select-content {
  ::v-deep .select-form {
    width: auto;
    display: block;
  }

  ::v-deep .select-form > div {
    display: inline-flex;
  }
}

.content-main {
  display: flex;
  padding: 0 20px;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 130px);

  .content-table {
    .table-statistics {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ebf4ff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;

      .statistics-left {
        display: flex;
        justify-content: space-between;

        div {
          margin-right: 20px;
        }

        div:first-child {
          margin-left: 10px;
        }
      }

      .statistics-right {
        display: flex;
        justify-content: space-between;

        div {
          margin-left: 20px;
        }

        div:last-child {
          margin-right: 10px;
        }
      }
    }
  }
}

.row-last:after {
  content: '';
  min-width: 300px;
}

.sync-data {
  top: 15px;
  right: 12px;
  position: absolute;
}
::v-deep .el-range-editor--mini .el-range-separator {
  line-height: inherit;
}
::v-deep .smcx-input .el-input__inner {
  height: 100%;
}
</style>
